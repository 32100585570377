
import './App.css';
import AppRouter from "./components/AppRouter";
import {Route} from "react-router-dom";

import React from "react";

function App() {


  return (
<>
  <AppRouter/>
</>
  );
}

export default App;
